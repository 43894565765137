import React from 'react';
import Paper from '@material-ui/core/Paper';
import StorageIcon from '@material-ui/icons/Storage';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PersonIcon from '@material-ui/icons/Person';
import {Container} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

/*const useStyles = makeStyles(theme => ({
  root : {
    display  : 'flex',
    flexWrap : 'wrap',
    '& > *'  : {
      margin : theme.spacing(1),
      width  : theme.spacing(16),
      height : theme.spacing(16),
    },
  },
}));*/

export default function BDBPaper() {
  const iconStyle = {
    fontSize : 72
  };
  const itemSize = 3;
  return (
    <Container maxWidth="md" style={{marginTop : '32px'}}>
      <Paper elevation={3}>
        <Grid container spacing={3} justify={'center'}>
          <Grid item xs={itemSize} style={{textAlign : 'center'}}>
            <StorageIcon
              color="primary"
              style={iconStyle}
              aria-label='Customized Schema Storage'/>
          </Grid>
          <Grid item xs={itemSize} style={{textAlign : 'center'}} aria-label='Customized Reporting'>
            <AssessmentIcon color="primary" style={iconStyle}/>
            {/*Customized Reporting*/}
          </Grid>
          <Grid item xs={itemSize} style={{textAlign : 'center'}} aria-label='Customized Permissions'>
            <PersonIcon color="primary" style={iconStyle}/>
            {/*Customized Permissions*/}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
