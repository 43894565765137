import React from 'react';
import logo from './logo.svg';
import './App.css';

import BDBAppBar from './Components/AppBar/AppBar';
import BDBPaper from './Components/Paper/Paper';
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import lime from '@material-ui/core/colors/lime';

const theme = createMuiTheme({
  palette : {
    primary   : blue,
    secondary : lime,
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BDBAppBar/>
      <BDBPaper/>
    </ThemeProvider>
  );
}

export default App;
